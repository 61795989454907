<script>
export default {
  methods: {
    // all the SHOW and DELIVER members except if the facility type is LOCT/COPY/TEST or the facility type is a 4 character code ending in X.
    checkFacilityType(facility_type) {
      //check if facility type is a 4 character code ending in X
      if (
        !facility_type ||
        (facility_type.length == 4 && facility_type.endsWith("X"))
      ) {
        return false;
      }

      // check to see if facility type is not in the array of facility types that are not required to respond
      const notRequiredFacilityType = ["LOCT", "COPY", "TEST", "ENCR", "PIGB"];
      return !notRequiredFacilityType.includes(facility_type);
    },
    totalMembersToShow(members) {
      let total = 0;

      if (!members) {
        return total;
      }
      members.forEach((member) => {
        if (this.showMember(member)) {
          total++;
        }
      });
      return total;
    },
    showMember(member) {
      if (
        member.show &&
        member.deliver &&
        this.checkFacilityType(member?.facility_type)
      ) {
        return true;
      }
      return false;
    },

    calculateResponsePercentage(ticket) {
      let membersResponses = {
        value: 0,
        responded: 0,
        total: 0,
      };
      let total = ticket.receipt.members.filter((member) =>
        this.showMember(member)
      );
      membersResponses.total = total.length;

      let latestResponses = {};

      if (!ticket.responses) return membersResponses;
      ticket.responses.forEach((response) => {
        if (response.member_code) {
          if (
            !latestResponses[response.member_code] ||
            new Date(
              latestResponses[response.member_code].responded
            ).getTime() < new Date(response.responded).getTime()
          ) {
            latestResponses[response.member_code] = response;
          }
        }
      });

      let responses = Object.values(latestResponses);

      let responded = responses?.length ?? 0;
      membersResponses.responded = responded;
      if (responded == 0 && membersResponses.total == 0) {
        membersResponses.value = 0;
      } else {
        membersResponses.value = (responded / membersResponses.total) * 100;
      }
      return membersResponses;
    },
  },
};
</script>
